import React from 'react';
import {connect} from 'react-redux';
import {UpdateMessage} from '../../redux/user/user.actions';
import {Dialog,DialogContent,Button,FormControl,
    TextField, FormLabel} from '@material-ui/core';


const EditMessage = ({open, handleClose, setMessage, message, messages,setMessages, UpdateMessage}) => {

    const handleChange = (e) => {
        const {name, value} = e.target
        setMessage({...message, [name]: value})
    }

    const handleSubmit = () => {
        UpdateMessage(message.id, message, callback)
    }

    const callback = (response) => {
        if(response.status){
            let index = messages.findIndex((i) => i.id === response.data.id);
            messages[index] = response.data;
            setMessages(messages)
            handleClose();
        }
    }

    return(
        <Dialog
        disableBackdropClick
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
            <FormControl fullWidth>
                <FormLabel>Message Title</FormLabel>
                <TextField
                    name="title"
                    onChange={handleChange}
                    text
                    fullWidth
                    variant="outlined"
                    rows={9}
                    margin="normal"
                    defaultValue={message ? message.title : ''}
                />
            </FormControl>

            <FormControl fullWidth>
                <FormLabel>Write Message</FormLabel>
                <TextField
                    name="message"
                    onChange={handleChange}
                    multiline
                    fullWidth
                    variant="outlined"
                    rows={9}
                    margin="normal"
                    defaultValue={message ? message.message : ''}
                />
            </FormControl>

                <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                size='small'
                style={{marginRight: '3px'}}
                >
                        Publish
                </Button>
                <Button
                onClick={handleClose}
                variant="contained"
                color="secondary"
                size='small'
                style={{marginLeft: '3px'}}
                >
                        close
                </Button>
            </DialogContent>
      </Dialog>
    )
}

// const mapStateToProps = createStructuredSelector({
//     organization: organization,
// });

export default connect(null, {UpdateMessage})(EditMessage)