
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
const states = require("../../JSONdocs/states.json")
const org_types = require("../../JSONdocs/organizationTypes.json")

const ViewOrganizationPopup = ({ organizations, setOrganizations, organization, setOrganization, open, handleClose }) => {

    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 400,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        input: {
            display: 'none',
        },
    }));
    const classes = useStyles();

    const [isAddingOrganization, setisAddingOrganization] = useState(false);

    const callback = (response) => {
        setisAddingOrganization(false);
        if (response.status) {
            let index = organizations.findIndex(org => org.id === response.data.id);
            organizations[index] = response.data;
            setOrganizations(organizations);
            handleClose()
        }
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setOrganization({ ...organization, [name]: value });
    }

    const handleSubmit = (event) => {
        setisAddingOrganization(true)
        event.preventDefault()
        // updateOrganization(organization, callback)
    }

    // const handleOrgTypeChange = (event) => {
    //     setSelectedOrgType(event.target.value);
    // };

    // const handleStateChange = (event) => {
    //     setSelectedState(event.target.value);
    // };

    return (
        <>
            
            <Dialog
            //disableBackdropClick
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title">
                <div style={{width: '500px'}}>
                    <form action="" onSubmit={(event) => handleSubmit(event)}>

                    <DialogTitle id="form-dialog-title">View Organization</DialogTitle>
                    <DialogContent>

                        <DialogContentText>Name: <h4><strong>{organization ? organization.name : ''}</strong></h4></DialogContentText>
                        
                        <DialogContentText> Location:
                            <h6>{organization ? organization.street_address : ''}</h6>
                        </DialogContentText>
                        <DialogContentText>City:
                            <h6>{organization ? organization.city : ''}</h6>
                        </DialogContentText>
                        <DialogContentText>State:<h6>{organization ? organization.state : ''}</h6>
                        </DialogContentText>
                        <DialogContentText>Subdomain Name:<h6>{organization ? organization.subdomain : ''}</h6>
                        </DialogContentText>
                        <DialogContentText>Type:
                            <h5>{organization ? organization.type : ''}</h5>
                        </DialogContentText>

                        {/* rganization ? organization.type : ''}</label> */}
                        {/* <TextField
                            margin="dense"
                            id=""
                            label="Organization name"
                            onChange={handleChange}
                            name='name'
                            type="text"
                            fullWidth
                            readOnly
                            value={organization ? organization.name : ''}
                            disabled
                        /> */}
                        
                        {/* <TextField
                            margin="dense"
                            id=""
                            name='street_address'
                            onChange={handleChange}
                            label="Street"
                            type="text"
                            fullWidth
                            readOnly
                            value={organization ? organization.street_address : ''}
                            disabled
                        /> */}
                        {/* <TextField
                            margin="dense"
                            id=""
                            name='city'
                            onChange={handleChange}
                            label="City"
                            type="text"
                            fullWidth
                            readOnly
                            value={organization ? organization.city : ''}
                            disabled
                            disableUnderline
                        /> */}
                        

                        {/* <FormControl className={classes.formControl}>
                            <Select
                                value={organization ? organization.state : ''}
                                name='state'
                                onChange={handleChange}
                                onClick={handleChange}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                                readOnly
                                fullWidth
                                disabled
                                disableUnderline
                            >

                                <MenuItem value="" disabled >
                                    State
                            </MenuItem>

                                {states.map(value => <MenuItem value={value.name}>{value.name}</MenuItem>)} 

                            </Select>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <Select
                                value={organization ? organization.type : ''}
                                name='type'
                                onChange={handleChange}
                                onClick={handleChange}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                                readOnly
                                disabled
                                fullWidth
                                
                                disableUnderline
                            >

                                <MenuItem value="" disabled >
                                    Organization Type
                            </MenuItem>

                                {org_types.map(value => <MenuItem value={value.name}>{value.name}</MenuItem>)} 

                            </Select>
                        </FormControl> */}

                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleClose} color="secondary">
                            Ok
                    </Button>

                    </DialogActions>
                </form>
                </div>
                
            </Dialog>

        </>
    )

}

export default ViewOrganizationPopup;