import api from '../../helpers/api';
import Notification from '../../helpers/notification'


export const addLevy = (levyData, callback) => async (dispatch) => {

    console.log(levyData)

    await api.post('levies', levyData).then((response) => {
        if (response.data.status) {
            callback(response.data);
            Notification.success('Levy Added Successfully!')
        } else {
            Notification.error(response.data.message)
            // console.log('error')
            // callback(response.data);
        }
    }).catch(error => Notification.error(error));
}


export const updateLevy = (levyData, callback) => async (dispatch) => {

    await api.put(`levies/${levyData.id}`, levyData).then((response) => {
        if (response.data.status) {
            // Notification.success('Levy Updated Successfully!')
            callback(response.data);
        } else {
            Notification.error(response.data.message)
            console.log('error')
        }
    }).catch(error => Notification.error(error));
}

export const listLevy = (callback) => async (dispatch) => {

    await api.get('levies').then((response) => {
        if (response.data.status) {
            callback(response.data);

        } else {
            Notification.error(response.data.message)
            console.log('error')
            // callback(response.data);

        }
    }).catch(error => Notification.error(error));
}

export const listLevyWithGroups = (callback) => async (dispatch) => {

    await api.get('levies/with-groups').then((response) => {
        if (response.data.status) {
            callback(response.data);

        } else {
            Notification.error(response.data.message)
            console.log('error')
            // callback(response.data);

        }
    }).catch(error => Notification.error(error));
}

export const deleteLevy = (id, callback) => async (dispatch) => {

    await api.delete(`levies/${id}`).then((response) => {
        if (response.data.status) {
            callback(id, response.data);

        } else {
            Notification.error(response.data.message)
            console.log('error')
            // callback(response.data);

        }
    }).catch(error => Notification.error(error));
}

export const getLevy = (id, callback) => async (dispatch) => {

    await api.get(`levies/${id}`).then((response) => {
        if (response.data.status) {
            callback(response.data.data);

        } else {
            Notification.error(response.data.message)
            console.log('error')
            // callback(response.data);

        }
    }).catch(error => Notification.error(error));
}
// All levies both paid and unpaid
export const UserAllLevies = (id,pageNumber, pageSize, callback) => async (dispatch) => {
    await api.get(`userlevy/${id}?page=${pageNumber}&size=${pageSize}`).then(response => {
        if (response.data.status){
            callback(response.data);
        }else console.log(Notification.error(response.data.message))
    }).catch(error => console.log(Notification.error(error)));
}
// unpaid levies
export const listUserUnpaidLevy = (loan_id,id,pageNumber, pageSize, callback) => async (dispatch) => {
    await api.get(`userlevy/${id}?status=unpaid&page=${pageNumber}&size=${pageSize}&loan_id=${loan_id}`).then(response => {
        if (response.data.status){
            callback(response.data);
        } else console.log(Notification.error(response.data.message))
    }).catch(error => console.log(Notification.error(error)));
}

export const listUserOustandingLevy = (id, callback) => async (dispatch) => {
    await api.get(`userlevy/${id}?status=part-payment`).then(response => {
        if (response.data.status) {
            callback(response.data);
        } else console.log(Notification.error(response.data.message))
    }).catch(error => console.log(Notification.error(error)));
}
// export const listUserUnpaidLevy = (id, callback) => async (dispatch) => {
//     await api.get(`levies/with-groups?organization_id=${id}`).then(response => {
//         if (response.data.status){
//             callback(response.data);
//         }else console.log(Notification.error(response.data.message))
//     }).catch(error => console.log(Notification.error(error)));
// }
export const listUserPaidLevy = (id, pageNumber, pageSize, callback) => async (dispatch) => {
    await api.get(`userlevy/${id}?status=paid&page=${pageNumber}&size=${pageSize}`).then(response => {
        if (response.data.status){
            callback(response.data);
        } else console.log(Notification.error(response.data.message))
    }).catch(error => console.log(Notification.error(error)));
}

export const updateUserLevy = (id, levyData, callback) => async (dispatch) => {
    await api.put(`userlevy/${id}`, levyData).then(response => {
        if (response.data.status) {
            callback(response.data);
        } else console.log(Notification.error(response.data.message))
    }).catch(error => console.log(Notification.error(error)));
}

export const getOneLevy = (id, user_id, levy_id, callback) => async (dispatch) => {
    await api.get(`/userlevygroups/${id}?user_id=${user_id}&levy_id=${levy_id}`).then(response => {
        if (response.data.status) {
            callback(response.data);
        } else console.log(Notification.error(response.data.message))
    }).catch(error => console.log(Notification.error(error)));
}

export const getLevyForReciept = (user_id, levy_id, callback) => async (dispatch) => {
    await api.get(`/payments/levy?levy_id=${levy_id}&user_id=${user_id}`).then(response => {
        if (response.data.status) {
            callback(response.data);
        } else console.log(Notification.error(response.data.message))
    }).catch(error => console.log(Notification.error(error)));
}

export const WithdrawBalance = (id, balance, callback) => async(dispatch) => {
    // "id" parameter is the levy id attached to the user
    await api.put(`userlevy/withdraw/${id}`, balance).then(
        response => {
            if(response.data.status){
                callback(response.data);
            }
        }
    ).catch(error => console.log(error))
}

