/* eslint-disable linebreak-style */
/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

export const selectUser = (state) => state.user;

// export const selectIsUserLoading = createSelector(
//   [selectUser],
//   (user) => user.isFetching
// );

export const currentUser = createSelector(
  [selectUser],
  (user) => user.currentUser
);

export const isAdmin = createSelector(
  [selectUser],
  (user) => {
    if (user.currentUser)
      return user.currentUser.role === "org_admin" ? true : false;
  }
);

export const isITL = createSelector(
  [selectUser],
  (user) => {
    if (user.currentUser)
      return user.currentUser.role === "itl_admin" ? true : false;
  }
);

export const isUser = createSelector(
  [selectUser],
  (user) => {
    if (user.currentUser)
      return user.currentUser.role === "user" ? true : false;
  }
);

// export const selectCurrentUserToken = createSelector(
//   [selectUser],
//   (user) => user.token
// );


// export const selectUserError = createSelector(
//   [selectUser],
//   (user) => user.errorMessage
// );

// export const selectRecovered = createSelector(
//   [selectUser],
//   (user) => user.recovered
// );
