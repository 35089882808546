import React from 'react';
import { createStructuredSelector } from 'reselect';
import {connect} from 'react-redux';
import {sendMessage} from '../../redux/user/user.actions';
import {Dialog,DialogContent,DialogTitle,Button,FormControl,
    TextField, FormLabel, Typography} from '@material-ui/core';
import { organization } from '../../redux/organization/organization.selectors';


const CreateMessage = ({open, handleClose, setMessages, messages, sendMessage, organization}) => {

    
    const [message, setMessage] = React.useState('')
    const id = organization.id
    const handleChange = (e) => {
        console.log(message)
        setMessage({...message,[e.target.name]: e.target.value, ["organization_id"]: id})
    }

    const handleSubmit = (e) => {
        sendMessage(message, callback)
    }

    const callback = (response) => {
        if(response.status){
            messages.push(response.data)
            setMessages(messages);
            // setMessage(response.data)
            handleClose()
        }
        
    }

    return(
        <Dialog
        disableBackdropClick
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
            <Typography variant="h6">{`Post message to ${organization ? organization .type != 'Microfinance' ? "Member" : "Customer" : null}`}</Typography>
            <FormControl fullWidth>
                <FormLabel>Message Title</FormLabel>
                <TextField
                        name="title"
                        onChange={handleChange}
                        text
                        fullWidth
                        variant="outlined"
                        margin="normal"
                    // style={{ fontSize: '1.5rem' }}
                />
            </FormControl>

            <FormControl fullWidth>
                <FormLabel>Message</FormLabel>
                <TextField
                        name="message"
                        onChange={handleChange}
                        multiline
                        fullWidth
                        variant="outlined"
                        rows={9}
                        margin="normal"
                    // style={{ fontSize: '1.5rem' }}
                />
            </FormControl>
                <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                size='small'
                style={{marginRight: '3px'}}
                >
                    Publish
                </Button>
                <Button
                onClick={handleClose}
                variant="contained"
                color="secondary"
                size='small'
                style={{marginLeft: '3px'}}
                >
                        close
                </Button>
            </DialogContent>
      </Dialog>
    )
}

const mapStateToProps = createStructuredSelector({
    organization: organization,
});

export default connect(mapStateToProps, {sendMessage})(CreateMessage)