import React from 'react';
import { useRoutes } from "react-router-dom";
import Themeroutes from './routes/Router'
import { Provider } from "react-redux";
import { store, persistor } from './redux/Store'
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@material-ui/styles';
import { theme } from './assets/jss/Theme-variable';
import GlobalStyles from './assets/jss/Globalstyles';
// import {toast} from 'react-toastify';
// import 'react-toastify/dist/react-toastify.cjs.development';

// const store = configureStore();
const App = () => {
  const routing = useRoutes(Themeroutes);

  return (
    // console.log(store),
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          {routing}
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
