import './user.actionTypes';
import api from '../../helpers/api'
import { USER_SIGN_IN_FAILURE, USER_SIGN_IN_SUCCESS, USER_SIGN_UP_FAILURE, USER_SIGN_UP_REQUEST, USER_SIGN_UP_SUCCESS, USER_LOG_OUT_REQUEST, EDIT_USER_PROFILE_SUCCESS } from './user.actionTypes';
import getStoredState from 'redux-persist/es/getStoredState';
import Notification from '../../helpers/notification'
import { data } from 'jquery';


/// Sign Up with Email
export const userSignUpRequest = () => ({
    type: USER_SIGN_UP_REQUEST
});

export const userSignUpReq = (userCredentials, callback, LoadingCallback) => async (dispatch) => {
    console.log('userSignUpRequest...');
    // dispatch(userSignUpReq());
    try {
        const response = await api.post('register', { ...userCredentials });
        if (response.data.status) {
            dispatch(signUpSuccess(response.data.data, response.data.token));
            callback(response.data.status)
            Notification.success('Registration Successful')
        } else {
            dispatch(signUpFailure(response.data.message));
            Notification.error(response.data.message)
        }
    } catch (error) {
        console.log(error);
        Notification.error(error);
        LoadingCallback();

    }
}

export const signUpSuccess = (user, additionalData) => ({
    type: USER_SIGN_UP_SUCCESS,
    payload: { user, additionalData }
});

export const signUpFailure = (error) => ({
    type: USER_SIGN_UP_FAILURE,
    payload: error
});


/// Sign In with Email

export const signInSuccess = (user, token) => ({
    type: USER_SIGN_IN_SUCCESS,
    payload: { user, token }
});

export const signInFailure = (error) => ({
    type: USER_SIGN_IN_FAILURE,
    payload: error
});

export const userSignInRequest = () => ({
    type: USER_SIGN_UP_REQUEST,
});

export const userLogoutRequest = () => ({
    type: USER_LOG_OUT_REQUEST,
});

export const updateProfileSuccess = (user) => ({
    type: EDIT_USER_PROFILE_SUCCESS,
    payload: user
});

// user update profile
export const updateProfile = (data) => async (dispatch) => {
    dispatch(updateProfileSuccess(data));
};

// user log out
export const logout = (callback) => async (dispatch) => {
    dispatch(userLogoutRequest());
    callback();
};

export const userSignInReq = (email, password, callback, LoadingCallback) => async (dispatch) => {
    //  dispatch(USER_SIGN_UP_REQUEST);
    try {
        const response = await api.post('login', { email, password });
        if (response.data.status) {
            dispatch(signInSuccess(response.data.data, response.data.token));
            //  console.log(getStoredState().user())
            Notification.success('Welcome Back');
            callback();
        } else {
            dispatch(signInFailure('Sorry Unable to Log you In'));
            Notification.error(response.data.message);
            LoadingCallback();
        }
    } catch (error) {
        LoadingCallback();
        dispatch(signInFailure(error));
        Notification.error(error)
    }
};

// admin add user endpoint
export const addUser = (userCredentials, callback) => async (dispatch) => {

    // console.log(userData)

    await api.post(`admin-register`, { ...userCredentials }).then((response) => {
        if (response.data.status) {
            Notification.success('User Added Successfully!')
            callback(response.data);

        } else {
            Notification.error(response.data.message)
            console.log('error')
            // callback(response.data);

        }
    }).catch(error => Notification.error(error));
}


//Find all Users
export const getAllUsers = (callback) => async () => {
    await api.get(`/users`).then((response) => {
        if (response.data.status) {
            callback(response.data);
        } else {
            console.error('something is Wrong')
        }
    }).catch(error => { console.log(error) })
}

//Find all New Users
export const getAllNewUsers = (callback) => async () => {
    await api.get(`/users/new`).then((response) => {
        if (response.data.status) {
            callback(response.data);
        } else {
            console.error('something is Wrong')
        }
    }).catch(error => { console.log(error) })
}

//Find One User
export const getOneUser = (id, callback) => async() => {
    api.get(`users/${id}`)
        .then((response) => {
            if (response.data.status) {
                callback(response.data.data);
            }
            else {
                console.error('Something Went Wrong');
            }
        }).catch(error => { console.error(error) })
};

export const saveDoc = (userid,docname, formData, config, callback) =>  async (dispatch) => {
    await api.put(`users/upload-document?user_id=${userid}&doctype=${docname}`, formData, config).then(response => {
        if (response.data.status) {
            Notification.success('Document uploaded')
            callback(response.data, `${docname}_url`)
        } else {
            Notification.error('Network Error, try again.')
        }
    }).catch(err => console.log(err))
}

//update or edit user
export const updateUser = (userData, callback) => async (dispatch) => {

    await api.put(`users/${userData.id}`, userData).then((response) => {
        if (response.data.status) {
            Notification.success('User Updated Successfully!')
            callback(response.data);

        } else {
            Notification.error(response.data.message)
            console.log('error')
        }
    }).catch(error => Notification.error(error));
}
//update or edit user
export const verifyUser = (levyData, callback) => async (dispatch) => {

    await api.post(`userlevygroups/new`, levyData).then((response) => {
        if (response.data.status) {
            Notification.success('User Updated Successfully!')
            callback(response.data);

        } else {
            Notification.error(response.data.message)
            console.log('error')
        }
    }).catch(error => Notification.error(error));
}

//Delete User
export const deleteOneUser = (id, callback) => async => {
    api.delete(`users/${id}`)
        .then((response) => {
            if (response.data.status) {
                callback(id, response.data);
            }
            else {
                console.error('Something Went Wrong');
            }
        }).catch(error => { console.error(error) })
};

// change password
export const changePassword = (id, password, callback) => async () => {
    await api.post(`change-password/${id}`, password).then((response) => {
        if (response.data.status) {
            callback(response.data);
            Notification.success('Password changed successfully');
        } else {
            Notification.error('Incorrect old password');
        }
    }).catch((error => {
        console.log(error);
        // Notification.error(error);
    }));
}

//// Recover Password
export const resetPassword = (email, callback) => async () => {
    await api.post(`email/change-password`, email).then((response) => {
        if (response.data.status) {
            callback(response.data)
        }
        else {
            callback(response.data);
        }
    }).catch(error => { Notification.error(error) })
};

//   reset password
export const recoverPassword = (data, callback) => async () => {
    await api.post('/forget-password', data).then((response) => {
        if (response.data.status) {
            callback(response.data)
            Notification.success(response.data.message)
        }
        else {
            Notification.error(response.data.message);
        }
    }).catch(error => { Notification.error(error) })
}

export const saveProfilePhoto = (userid, imgData, callback, config) => async (dispatch) => {
    await api.put(`users/upload-picture?user_id=${userid}`, imgData, config).then(response => {
        if (response.data.status) {
            callback(response.data)
            Notification.success('Successfully Sent')
        } else {
            Notification.error('Network Error, try again.')
        }
    }).catch(err => console.log(err))
}

export const sendMessage = (messageData, callback) => async (dispatch) => {
    await api.post(`post-message`, messageData).then(response => {
        if (response.status) {
            callback(response.data)
            Notification.success("Message Sent");
        } else Notification.warning("Message not sent.Check your internet")
    }).catch(
        err => console.log(err)
    )
}

export const AllMessage = (page, size, callback) => async (dispatch) => {
    await api.get(`post-message?page=${page}&size=${size}`).then(response => {
        if (response.status) {
            callback(response.data)
        } else {
            console.error('Something Went Wrong');
        }
    }).catch(
        err => console.log(err)
    )
}

export const DeleteMessage = (id, callback) => async (dispatch) => {
    await api.delete(`post-message/${id}`).then(response => {
        if (response.status) {
            callback(id, response.data)
            Notification.success("Message Deleted")
        } else {
            console.error('Something Went Wrong');
        }
    }).catch(
        err => console.log(err)
    )
}

export const UpdateMessage = (message_id, messageData, callback) => async (dispatch) => {
    await api.put(`post-message/${message_id}`, messageData).then(response => {
        if (response.status) {
            callback(response.data)
            Notification.success('Message Updated')
        } else {
            Notification.error('Network Error, try again.')
        }
    }).catch(err => console.log(err))
}

// users in microfinance to create how it want's to deposit
export const listDeposit = (user_id,callback) => async (dispatch) => {

    await api.get(`levies/with-group&user_id=${user_id}`).then((response) => {
        if (response.data.status) {
            callback(response.data);

        } else {
            Notification.error(response.data.message)
            console.log('error')
            // callback(response.data);

        }
    }).catch(error => Notification.error(error));
}

export const deleteDeposit = (id, callback) => async (dispatch) => {

    await api.delete(`levies/${id}`).then((response) => {
        if (response.data.status) {
            callback(id, response.data);

        } else {
            Notification.error(response.data.message)
            console.log('error')
            // callback(response.data);

        }
    }).catch(error => Notification.error(error));
}

// customer withdraw fund
export const withdrawFund = (levy_id,user_id,withdrawRequest,callback) => async (dispatch) => {
    await api.put(`withdrawals/${levy_id}?user_id=${user_id}`, withdrawRequest)
    .then((response) => {
        if(response.status){
            callback(response.data)
            // Notification.warning(response.message)
        }
    })
    .catch(err => Notification.error(err))
}

// get withdrawal request
export const getWithdrawals = (page,size,callback) => async (dispatch) => {
    await api.get(`withdrawals/?page=${page}&size=${size}`).then(response => {
        if(response.status){
            callback(response.data)
        }
    }).catch(err => Notification.error(err))
}

// update withdrawal request status
export const updateWithdrawRequest = (user,callback) => async(dispatch) => {
    await api.put(`withdrawals/update-widthrawal/${user.id}?levy_id=${user.levy_id}`, {...user, status: "successful"})
    .then(resp => {
        if(resp.status){
            callback(resp)
            Notification.success(resp.message)
        }
    }).catch(err => Notification.error(err))
}



