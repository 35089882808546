import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { getAllUsers, deleteOneUser } from '../redux/user/user.actions';
import { createStructuredSelector } from 'reselect';
import {connect} from 'react-redux';
import {
    Card,Dialog,DialogActions,DialogContent,DialogContentText,
    DialogTitle,CardContent,CardHeader, TextField, FormLabel
} from '@material-ui/core';
import { Table } from 'react-bootstrap';
import { Input, FormControl, InputLabel, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { AddCircleOutline } from '@material-ui/icons';
import Swal from 'sweetalert2';
import Organization from '../views/Organization';
import { organization } from '../redux/organization/organization.selectors';
import  CreateMessage  from '../Components/Post_Message/CreateMessage'
import  EditMessage  from '../Components/Post_Message/EditMessage'
import { AllMessage, DeleteMessage } from '../redux/user/user.actions'
import moment from 'moment'

const style = {
    display: 'flex',
    flexWrap: 'nowrap',
}

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const PostTable = ({sno, message, handleEditOpen, delMessage}) => {
    const classes = useStyles()
    // console.log(message.title)
    return (
        <tr>
            <td>{sno}</td>
            <td>{message.title}</td>
            <td>{message.message}</td>
            <td>{moment(message.createdAt).format("Do MMMM YY")}</td>
            <td style={{ width: '150px' }}>
                <div style={style} >

                    {/* <div>
                        <Link to="/verifyuser">
                            <Button style={{backgroundColor: "green", color: "white"}}
                            variant="contained"
                            //color="green"
                            size='small'
                            className={classes.button}
                            startIcon={<VerifiedUserIcon />}
                            onClick={() => handleEditOpen(user)}
                        >
                            Verify
                        </Button>
                        </Link>
                        
                    </div> */}
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            size='small'
                            className={classes.button}
                            startIcon={<EditIcon />}
                            onClick={() => handleEditOpen(message)}
                        >
                            Edit
                        </Button>
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            color="secondary"
                            size='small'
                            className={classes.button}
                            startIcon={<DeleteIcon />}
                            onClick={() => Swal.fire({
                                title: 'Are you sure?',
                                text: "You won't be able to undo this process!",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Delete!'
                            }).then((result) => {
                                if (result.isConfirmed && delMessage(message.id)) {
                                    Swal.fire(
                                        'Deleted!',
                                        'User has been deleted.',
                                        'success'
                                    )
                                }
                            })}
                        >
                            Delete
                            </Button>
                    </div>
                    
                </div></td>
        </tr>
    )
}


const CreateInformation = ({AllMessage, DeleteMessage, organization }) => {

    const [searchText, setSearchText] = useState(false);
    const [searchFilter, setSearchFilter] = useState('');
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [messages, setMessages] = useState([])
    const [message, setMessage] = useState('')
    
    const [pageSize] = useState(20);
    const [currentPage] = useState(1)

    useEffect(() => {
        AllMessage(currentPage, pageSize, callback)
    },[])

    const callback = (response) => {
        setMessages(response.data)
    }

    const lastmessage = (recent) => {
        return recent.reverse();
    }

    const delMessage = async (id) => {
        await DeleteMessage(id, delCallback)
    }

    const delCallback = (id, response) => {
        setMessages(messages.filter(message => message.id !== id));
        return response.status;
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEditOpen = (message) => {
        setMessage(message)
        setEdit(true);
    }

    const handleEditClose = () => {
        setEdit(false);
    }

    const handleSearch = (event) => {
        event.preventDefault()
        // setSearchText(event.target.value.toLowerCase())
    }

    // const SentMessages = () => {
    //     if (searchText) {
    //         switch (searchFilter) {
    //             case 'name':
    //                 return messages.filter(data => (data.name || '-').toLowerCase().includes(searchText)).map(value => <PostTable delUser={delUser} key={value.id} handleEditOpen={handleEditOpen} message={value} />)

    //             case 'user_type':
    //                 return messages.filter(data => (data.user_type || '-').toLowerCase().includes(searchText)).map(value => <PostTable delUser={delUser} key={value.id} handleEditOpen={handleEditOpen} message={value} />)

    //             default:
    //                 return messages.filter(data => (data.name || '-').toLowerCase().includes(searchText)).map(value => <PostTable delUser={delUser} key={value.id} handleEditOpen={handleEditOpen} message={value} />)
    //         }
    //     } else {
    //         return messages.map((value, index) => <PostTable sno={index + 1} delUser={delUser} key={value.id} handleEditOpen={handleEditOpen} message={value} />)
    //     }
    // }

    const classes = useStyles();
    return (

        <>
            <CreateMessage open={open} handleClose={handleClose}
            messages={messages} setMessages={setMessages}/>
            <EditMessage open={edit} handleClose={handleEditClose} message={message} setMessage={setMessage}
            messages={messages} setMessages={setMessages}/>
            <Card>
                <CardHeader titleTypographyProps={{ variant: 'h4' }} title={organization.name} />
                <CardContent>
                    <div style={{ margin: '15px 0', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        <Button
                            onClick={handleClickOpen}
                            variant="contained"
                            color="primary"
                            size='small'
                            className={classes.button}
                            startIcon={<AddCircleOutline />}
                        >
                            Create Message
                        </Button>
                    </div>

                    <div class="lds-facebook"><div></div><div></div><div></div></div>
                    <Table responsive='lg' striped bordered>
                        <thead>
                            <tr>
                                <th>S/N</th>
                                <th>Message Title</th>
                                <th>Message Body</th>
                                <th>Date Sent</th>
                                <th style={{ textAlign: 'center' }}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {messages.length ? lastmessage(messages).map((v,idx) => <PostTable sno={idx + 1} key={v.id} delMessage={delMessage} message={v} handleEditOpen={handleEditOpen}/>) : <tr> <td colSpan="7" style={{ textAlign: 'center' }}></td> </tr>}
                        </tbody>
                    </Table>
                </CardContent>
            </Card>

        </>
    );

}

const mapStateToProps = createStructuredSelector({
    organization: organization,
});

export default connect(mapStateToProps, {AllMessage, DeleteMessage})(CreateInformation)