
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { addOrganization } from '../../redux/organization/organization.actions';
const org_types = require("../../JSONdocs/organizationTypes.json")
const states = require("../../JSONdocs/states.json")

//////Add Organization Pop-up Component
const AddOrganizationPopup = (
    { addOrganization, organizations, setOrganizations, open, handleClose }) => {


    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        input: {
            display: 'none',
        },
    }));
    const classes = useStyles();

    const [isAddingOrganization, setisAddingOrganization] = useState(false);
    const [selectedOrgType, setSelectedOrgType] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [organizationData, setOrganizationData] = useState({ "user_id": 1 });

    const callback = (response) => {
        setisAddingOrganization(false);
        if (response.status) {
            organizations.push(response.data)
            setOrganizations(organizations);
            handleClose()
        }
    }
    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        // console.log(organizationData)
        setOrganizationData({ ...organizationData, [name]: value });
    }

    const handleSubmit = (event) => {
        setisAddingOrganization(true)
        event.preventDefault()
        addOrganization(organizationData, callback)
    }

    const handleOrgTypeChange = (event) => {
        setSelectedOrgType(event.target.value);
    };

    const handleStateChange = (event) => {
        setSelectedState(event.target.value);
    };

    return (
        <>

            <Dialog disableBackdropClick open={open} onClose={handleClose} aria-labelledby="form-dialog-title">

                <form action="" onSubmit={(event) => handleSubmit(event)}>

                    <DialogTitle id="form-dialog-title">Add Organization</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Enter details for your new organization
                        </DialogContentText>

                        <TextField
                            margin="dense"
                            id=""
                            label="Organization name"
                            onChange={handleChange}
                            name='name'
                            type="text"
                            fullWidth
                            required
                        />
                        <TextField
                            margin="dense"
                            id=""
                            name='street_address'
                            onChange={handleChange}
                            label="Street"
                            type="text"
                            fullWidth
                            required
                        />
                        <TextField
                            margin="dense"
                            id=""
                            name='city'
                            onChange={handleChange}
                            label="City"
                            type="text"
                            fullWidth
                            required
                        />

                        <FormControl className={classes.formControl}>
                            <Select
                                value={selectedState}
                                name='state'
                                onChange={handleStateChange}
                                onClick={handleChange}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                                required
                                fullWidth
                            >

                                <MenuItem value="" disabled >
                                    State
                            </MenuItem>

                                {states.map(value => <MenuItem value={value.name}>{value.name}</MenuItem>)} {/* Organization Type list */}

                            </Select>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <Select
                                value={selectedOrgType}
                                name='type'
                                onChange={handleOrgTypeChange}
                                onClick={handleChange}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                                required
                                fullWidth
                            >

                                <MenuItem value="" disabled >
                                    Organization Type
                            </MenuItem>

                                {org_types.map(value => <MenuItem value={value.name}>{value.name}</MenuItem>)} {/* Organization Type list */}

                            </Select>
                        </FormControl>

                    </DialogContent>

                    <div className={classes.root} style={{ marginLeft: '7px' }}>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            multiple
                            type="file"
                        />
                        <input accept="image/*" className={classes.input} id="icon-button-file" type="file" />
                        <label htmlFor="icon-button-file">
                            <IconButton color="primary" aria-label="upload picture" component="span">
                                <PhotoCamera />
                                <Typography>
                                    Upload Logo
                            </Typography>
                            </IconButton>
                        </label>
                    </div>
                    <DialogActions>

                        <Button onClick={handleClose} color="secondary">
                            Cancel
                    </Button>
                        {
                            isAddingOrganization ? <Button disabled type="submit" color="primary"> {/* Condition during API call to add organization */}
                                Creating organization......
                            </Button> : <Button type="submit" color="primary">
                                Add
                    </Button>
                        }

                        {/* <Button disabled type="submit" color="primary">
                            Creating organization......
                    </Button> */}
                        {/* <Button type="submit" onSubmit={(event) => handleSubmit(event)} color="primary">
                            Add
                    </Button> */}

                    </DialogActions>
                </form>
            </Dialog>

        </>
    )

}

const mapDispatchToProps = () => {
    return { addOrganization }
}

export default connect(
    null,
    mapDispatchToProps(),
)(AddOrganizationPopup);