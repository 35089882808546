import axios from 'axios';
import { store } from '../redux/Store';
import { getOrgDetails } from '../redux/organization/organization.actions';
import Swal from 'sweetalert2';
import { logout } from '../redux/user/user.actions';
import Notification from './notification';

// const URL = 'https://cipma-api.herokuapp.com/api/v1/';
// const URL = 'http://localhost:5000/api/v1/';
const URL = 'https://api.cipma.ng/api/v1/';

const instance = axios.create({
  baseURL: URL,
});

instance.interceptors.request.use(async (config) => {
  Swal.fire({
    title: 'Please Wait !',
    html: 'Loading',// add html attribute if you want or remove
    allowOutsideClick: false,
    showConfirmButton: false,
    onBeforeOpen: () => {
      Swal.showLoading()
    },
  });
  const { token } = store.getState().user;
  config.headers.Authorization = token ? `Bearer ${token}` : null;
  // const host = /:\/\/([^\/]+)/.exec(window.location.href)[1];
  const host = /:\/\/([^\/]+)/.exec("http://ashamfb.cipma.ng")[1];
  // const host = /:\/\/([^\/]+)/.exec("http://cipma.ng")[1];
  const callback = (organization) => {
    if (organization) {
      const organization_id = organization.id;
      config.params = { organization_id };
    } else {
      if (host.split('.')[0] !== "cipma" && host.split('.')[0] !== "www") {
        window.location.replace(`/host-not-found?host=${host}`)
      }
    }
  }

  // bug detected, store not fetching current details

  const subdomain = host.split('.')[0] !== "cipma" && host.split('.')[0] !== "www" ? host.split('.')[0] : "itl";
  let org = store.getState().organization;
  if (org && Object.keys(org).length !== 0 && org.subdomain === subdomain) {
    callback(org);
  } else {
    await store.dispatch(getOrgDetails(subdomain, URL, callback));
  }
  return config;
});

instance.interceptors.response.use(response => {
  Swal.close();
  return response;
}, error => {
  Swal.close();
  console.log(error)
  if (!error.response) {
    Notification.error('Network Error');
  }
  else if (error.response.status === 401) {
    // store.dispatch(logout());
    // window.location.pathname = "/";
    // Notification.error('Token has expired. Sign in to continue.');
  }
  else if (error.response.status === 400) {
    Notification.error('Bad Request');
  }
  else if (error.response.status === 403) {
    Notification.error("Forbiden");
  }
  else if (error.response.status === 404) {
    Notification.error("Not found");
  }
  else if (error.response.status === 409) {
    // store.dispatch(logout());
    // window.location.pathname = "/";
    // Notification.error("You are not signed in into this organization. Sign in to continue.");
  }
  else if (error.response.status === 500) {
    Notification.error("Internal server error");
  }
  return Promise.reject(error);
});

export default instance;