
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { updateOrganization } from '../../redux/organization/organization.actions';
import { connect } from 'react-redux';
const states = require("../../JSONdocs/states.json")
const org_types = require("../../JSONdocs/organizationTypes.json")

const EditOrganizationPopup = ({ organizations, setOrganizations, organization, setOrganization, open, handleClose, updateOrganization }) => {

    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        input: {
            display: 'none',
        }
    }));
    const classes = useStyles();

    const [isAddingOrganization, setisAddingOrganization] = useState(false);

    const callback = (response) => {
        setisAddingOrganization(false);
        if (response.status) {
            let index = organizations.findIndex(org => org.id === response.data.id);
            organizations[index] = response.data;
            setOrganizations(organizations);
            handleClose()
        }
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setOrganization({ ...organization, [name]: value });
    }

    const handleSubmit = (event) => {
        setisAddingOrganization(true)
        event.preventDefault()
        updateOrganization(organization, callback)
    }

    // const handleOrgTypeChange = (event) => {
    //     setSelectedOrgType(event.target.value);
    // };

    // const handleStateChange = (event) => {
    //     setSelectedState(event.target.value);
    // };

    return (
        <>

            <Dialog disableBackdropClick open={open} onClose={handleClose}  aria-labelledby="form-dialog-title"  
            // backgroundColor='red'
            // hideBackdrop='true'
            // PaperComponent={{style:{boxShadow:'none'}}}
            // style={{background:'rgba(0,0,0,0.1)', boxShadow: 'none'}}
            // BackdropProps={{
            //     style: {
            //         backgroundColor: 'red',
            //         boxShadow: 'none'
            //     },
            // }}
            
            // PaperProps={{style:{boxShadow:'red', overflow:'hidden'},}} 
            >
                <form action="" onSubmit={(event) => handleSubmit(event)}>

                    <DialogTitle id="form-dialog-title">Edit Organization</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Edit this organization
                        </DialogContentText>

                        <TextField
                            margin="dense"
                            id=""
                            label="Organization name"
                            onChange={handleChange}
                            name='name'
                            type="text"
                            fullWidth
                            required
                            value={organization ? organization.name : ''}
                        />
                        <TextField
                            margin="dense"
                            id=""
                            name='street_address'
                            onChange={handleChange}
                            label="Street"
                            type="text"
                            fullWidth
                            required
                            value={organization ? organization.street_address : ''}
                        />
                        <TextField
                            margin="dense"
                            id=""
                            name='city'
                            onChange={handleChange}
                            label="City"
                            type="text"
                            fullWidth
                            required
                            value={organization ? organization.city : ''}
                        />
                        <FormControl className={classes.formControl}>
                            <Select
                                value={organization ? organization.state : ''}
                                name='state'
                                onChange={handleChange}
                                onClick={handleChange}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                                required
                                fullWidth
                            >
                                <MenuItem value="" disabled >
                                    State
                                </MenuItem>

                                {states.map(value => <MenuItem value={value.name}>{value.name}</MenuItem>)} {/* Organization Type list */}

                            </Select>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <Select
                                value={organization ? organization.type : ''}
                                name='type'
                                onChange={handleChange}
                                onClick={handleChange}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                                required
                                fullWidth
                            >

                                <MenuItem value="" disabled >
                                    Organization Type
                            </MenuItem>

                                {org_types.map(value => <MenuItem value={value.name}>{value.name}</MenuItem>)} {/* Organization Type list */}

                            </Select>
                        </FormControl>

                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleClose} color="secondary">
                            Cancel
                    </Button>
                        {
                            isAddingOrganization ? <Button disabled type="submit" color="primary"> {/* Condition during API call to add organization */}
                                Updating organization......
                            </Button> : <Button type="submit" color="primary">
                                Update
                    </Button>
                        }

                        {/* <Button disabled type="submit" color="primary">
                            Creating organization......
                    </Button> */}
                        {/* <Button type="submit" onSubmit={(event) => handleSubmit(event)} color="primary">
                            Add
                    </Button> */}

                    </DialogActions>
                </form>
            </Dialog>

        </>
    )

}
const mapDispatchToProps = () => {
    return { updateOrganization }
}

export default connect(
    null,
    mapDispatchToProps(),
)(EditOrganizationPopup);