import React, { useState, useEffect } from "react";
import {
  UserAllLevies,
  WithdrawBalance,
  updateUserLevy,
} from "../../redux/levy/levy.actions";
import { connect } from "react-redux";
import { getOneUser } from "../../redux/user/user.actions";
import { useLocation, Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import Notification from "../../helpers/notification";
import { currencyFormat } from "../../helpers/hookLib/helperFunction";
import {
  Card,
  CardContent,
  CardHeader,
  TextField,
  Dialog,
  DialogContentText,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Checkbox,withStyles
} from "@material-ui/core";
import { Table } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import {
  organization,
} from "../../redux/organization/organization.selectors";
import NavigateNextTwoToneIcon from "@material-ui/icons/NavigateNextTwoTone";
import NavigateBeforeTwoToneIcon from "@material-ui/icons/NavigateBeforeTwoTone";
import { purple } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table_top: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  pagination: {
    width: "19%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "17px",
    marginTop: "10px",
  },
  inner: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const ManualPaymentModal = (props) => {
  var trxRef, refNum;
  trxRef = transGenenerator();
  refNum = refGenerator();
  const levyData = {
    levy: {
      id: props.data ? props.data.levy_id : "",
      name: props.data ? props.data.levy.name : "",
      amount: props.amount,
    },
    payment: {
      message: "approved",
      reference: refNum,
      status: "success",
      trans: trxRef,
      transaction: trxRef,
      trxref: refNum,
    },
    payment_method: "cash",
    user_levy_group_id: props.data ? props.data.id : "",
  };
  function refGenerator() {
    const e = new Date();
    const time = e.getTime();
    return "x".replace(/[x]/g, function (c) {
      var t = c == "x" ? (props.user ? props.user.id + "" + time : "") : "";
      return t.toString();
    });
  }

  function transGenenerator() {
    const e = new Date();
    const time = e.getTime();
    return "x".replace(/[x]/g, function (c) {
      var t = c == "x" ? (props.user ? props.user.id + "" + time : "") : "";
      return t.toString();
    });
  }

  const total = Number(props.data.amount_paid) + Number(props.amount);
  const handleSubmit = () => {
    if (props.data) {
      if (props.data.levy.instalmental_plan === "yes") {
        if (total > Number(props.data.levy_group.amount)) {
          Notification.error("Can not pay more than the set bill amount");
        } else if (
          Number(props.amount) < Number(props.data.levy_group.minimum_amount) && Number(props.data.balance) > Number(props.data.levy_group.minimum_amount)
        ) {
          Notification.error(
            "Minmum amount payable is " + props.data.levy_group.minimum_amount
          );
        }  else if (
          Number(props.amount) < Number(props.data.balance) && Number(props.data.balance) < Number(props.data.levy_group.minimum_amount)
        ) {
          Notification.error(
            "Minmum amount payable is " + props.data.balance
          );
        }else props.updateUserLevy(props.user.id, levyData, callback);
      } else {
        if (
          Number(props.data.balance) < Number(props.amount) ||
          Number(props.amount) < Number(props.data.balance)
        ) {
          Notification.warning(
            "Payable amount is " + props.data.balance
          );
        } else props.updateUserLevy(props.user.id, levyData, callback);
      }
    }
  };

  const callback = (response) => {
    if (response.status) {
      window.location.reload();
    }
  };

  return (
    <Dialog open={props.open} onClose={props.close}>
      <DialogTitle>
        Manual payment of cash recieved from{" "}
        {props.organization.type === "Microfinance" ? "customer" : "member"}
      </DialogTitle>
      <DialogTitle>
        <DialogContentText>
          {props.data ? props.data.levy.name : ""}
        </DialogContentText>
      </DialogTitle>
      <DialogContent>
        <TextField
          onChange={props.handleField}
          value={props.amount}
          margin="dense"
          label="Cash Recieved"
          type="number"
          fullWidth
          placeholder={
            "Minimum amount must be " + currencyFormat(props.minimum_amount)
          }
        />
        <Button
          onClick={handleSubmit}
          className="btn_submit"
          variant="contained"
          color="primary"
        >
          Proceed
        </Button>
      </DialogContent>
    </Dialog>
  );
};
const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
    },
  },
}))(Button);

const BillsData = ({ sno, data, withdraw, modalOpen, user }) => {
  const bal = data.balance.toString().slice(1);
  const xBal = { amount: bal };
  // const [value, setValue] = useState('')
  const url_R = `/print-receipt/${data.id}?levy_id=${data.levy_id}&ux=${user.id}`
  const handleWithdrawSubmit = async () => {
    await withdraw(data.id, xBal, callback);
  };

  const callback = (response) => {
    if (response.status) {
      window.location.reload();
    }
  };

  const PrintReciept = () => {
      return (
        <Link  to={url_R}>
          <ColorButton>
            Print Reciept
          </ColorButton>
        </Link>
      )
  }

  const getChange = () => {
    if (data.balance < 0) {
      return (
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleWithdrawSubmit()}
        >
          Withdrawal {bal}
        </Button>
      );
    } else {
      if (data.balance !== 0 && data.balance > 0) {
        return (
          <Button color="primary" onClick={() => modalOpen(data)}>
            Cash Payment
          </Button>
        );
      } else return <PrintReciept />;
    }
  };

  // const handleClick = (e) => {
  //   const queryId = [document.getElementById(data.id).getAttribute('id')]
  //   const checked = queryId.find(element =>
  //     element === e.target.id
  //   )
  //   setValue(checked)
  //   console.log(value)
  // }

  return (
    <tr>
      {/* <td><Checkbox id={data.id} onChange={(e) => handleClick(e)} /></td> */}
      <td>{sno}</td>
      <td>{data.levy !== null ? data.levy.name : ""}</td>
      <td>{data.levy_group.amount}</td>
      <td>{data.amount_paid}</td>
      <td>{data.balance}</td>
      <td>{data.levy_group.deadline}</td>
      <td>{data.status}</td>
      <td style={{textAlign: "center"}}>{getChange()}</td>
    </tr>
  );
};

const UserViewsLevies = ({
  UserAllLevies,
  WithdrawBalance,
  getOneUser,
  organization,
  updateUserLevy,
}) => {
  const search = useLocation().search;
  const user_id = new URLSearchParams(search).get("view");
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [levies, setLevies] = useState([]);
  const [customer, setCustomer] = useState({});
  const [levy, setLevy] = useState("");

  const [currentPage, setCurrentPage] = React.useState(1);
  const [nextPage, setNextPage] = React.useState(1);
  const [prevPage, setPrevPage] = React.useState(0);
  const [pageSize] = React.useState(20);
  const [pageLimit] = React.useState(3);
  const [totalItems, setTotalItems] = React.useState(null);
  const [totalPages, setTotalPages] = React.useState(1);
  const [color, setColor] = React.useState("grey");
  const [amount, setAmount] = useState("");

  const fetchData = (pageNumber = currentPage) => {
    UserAllLevies(user_id, pageNumber, pageSize, callback);
  };
  useEffect(async () => {
    fetchData();
    await getOneUser(user_id, uCallback);
  }, []);
  const callback = ({ data, currentPage, totalPages, totalItems }) => {
    setLevies(data);
    setCurrentPage(currentPage);
    setNextPage(currentPage + 1);
    setPrevPage(currentPage - 1);
    setTotalItems(totalItems);
    setTotalPages(totalPages);
  };

  const uCallback = (data) => {
    // console.log(customer)
    setCustomer(data);
  };

  const paginate = (page) => {
    fetchData(page);
  };

  const pageNumb = () => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    if (start > totalPages) {
      return new Array().fill((_, idx) => start + idx + 1);
    } else return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
  };

  const paginatePrev = () => {
    if (currentPage > 1) {
      fetchData(prevPage);
      const prev = color === "black" ? "black" : "grey";
    } else {
      const prev = color === "grey" ? "grey" : "black";
    }
  };

  const paginateNext = () => {
    if (currentPage < totalPages) {
      fetchData(nextPage);
      const next = color === "black" ? "black" : "grey";
    } else {
      const next = color === "grey" ? "grey" : "black";
    }
  };

  const handleClickOpen = (levy) => {
    setLevy(levy);
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
    setAmount("");
  };

  const handleChange = (e) => {
    setAmount(e.target.value);
  };

  return (
    <>
      <ManualPaymentModal
        open={open}
        close={handleClickClose}
        organization={organization}
        handleField={handleChange}
        minimum_amount={levy ? levy.levy_group.minimum_amount : ""}
        data={levy}
        amount={amount}
        user={customer}
        updateUserLevy={updateUserLevy}
        fetchData={fetchData}
      />
      <Card>
        <CardContent>
          <div className={classes.table_top}>
            <div>
              <Typography variant="h5">Bills Due</Typography>
              {organization.type === "Microfinance" ? (
                <Typography variant="p">
                  {"Customer: " +
                    customer.first_name +
                    " " +
                    customer.last_name}
                </Typography>
              ) : (
                <Typography variant="p">
                  {"Member: " + customer.first_name + " " + customer.last_name}
                </Typography>
              )}
              {totalItems < pageSize ? null : (
                <div className={classes.pagination}>
                  <div
                    onClick={() => paginatePrev()}
                    id={currentPage === 1 ? "prev" : ""}
                    className={classes.nav}
                  >
                    <NavigateBeforeTwoToneIcon />
                  </div>
                  <div className={classes.inner}>
                    {pageNumb().map((page, index) => (
                      <button
                        key={index}
                        onClick={() => paginate(page)}
                        className={
                          page === currentPage ? "btnactive" : "btnpagination"
                        }
                        disabled={page > totalPages}
                        style={{ display: page > totalPages ? "none" : "" }}
                      >
                        <span>{page}</span>
                      </button>
                    ))}
                  </div>
                  <div
                    onClick={() => paginateNext()}
                    id={currentPage === totalPages ? "next" : ""}
                    className={classes.nav}
                  >
                    <NavigateNextTwoToneIcon />
                  </div>
                </div>
              )}
            </div>
            <ul style={{ listStyle: "none" }}>
              <li>
                <Link to={`/userdetails`}>
                  Go Back
                </Link>
              </li>
              {/* <li>
                <Link to={`/userdetails/unpaid/?view=${user_id}`}>
                  Unpaid Bills
                </Link>
              </li> */}
            </ul>
          </div>
          <Table responsive="lg" striped bordered>
            <thead>
              <tr>
                {/* <th></th> */}
                <th>S/N</th>
                <th>Description</th>
                <th>Amouny Due</th>
                <th>Amount Paid</th>
                <th>Balance</th>
                <th>Deadline</th>
                <th>Status</th>
                {levies.find((v) => v.balance < 0) || levies.find(v => v.balance > 0) || levies.find(v => v.status === "paid") ? <th>Action</th> : null}
              </tr>
            </thead>
            <tbody>
              {levies.length ? (
                levies.map((bill, index) => (
                  <BillsData
                    sno={index + 1}
                    key={bill.id}
                    data={bill}
                    withdraw={WithdrawBalance}
                    user={customer}
                    levy={levy}
                    setLevy={setLevy}
                    modalOpen={handleClickOpen}
                  />
                ))
              ) : (
                <tr>
                  {" "}
                  <td colSpan="9" style={{ textAlign: "center" }}>
                    No Bills found
                  </td>{" "}
                </tr>
              )}
            </tbody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  organization: organization,
});

export default connect(mapStateToProps, {
  UserAllLevies,
  getOneUser,
  WithdrawBalance,
  updateUserLevy,
})(UserViewsLevies);
