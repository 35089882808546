import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import EditOrganizationPopup from './EditOrganizationPopup.component';
import ViewOrganizationPopup from './ViewOrganizationPopup.component';
import { getOrganization, deleteOrganization } from '../../redux/organization/organization.actions';
import React from 'react';
import { connect } from 'react-redux';
import { Delete, Edit, LocationOn } from '@material-ui/icons';

/////Organization Card(s) Component
const OrganizationCard = ({ setOrganization, selectedOrganization, setOrganizations, organizations, organization, view, setView, edit, setEdit, deleteOrganization }) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
            padding: '10px',
            boxShadow: '0px 0px 20px #CCCCCC',
            margin: '20px 0'
        },
        chip: {
            margin: theme.spacing(0.5),
        },
        section1: {
            margin: theme.spacing(3, 2),
        },
        section2: {
            margin: theme.spacing(2),
        },
        section3: {
            margin: theme.spacing(3, 1, 1),
        },
        minH: {
            minHeight: '60px',
        }
    }));

    const deleteOrg = async (id) => {
        await deleteOrganization(id, delCallback)
    }

    const delCallback = (id, response) => {
        setOrganizations(organizations.filter(org => org.id !== id));
        return response.status;
    }

    const handleEditOpen = () => {
        setOrganization(organization)
        setEdit(true);
    }

    const handleViewOpen = () => {
        setOrganization(organization)
        setView(true);
    }

    const handleEditClose = () => {
        setEdit(false);
    }

    const handleViewClose = () => {
        setView(false);
    }

    const classes = useStyles();
    const url = `/organizations/${organization.id}`

    return (
        <>
            <EditOrganizationPopup 
            setOrganizations={setOrganizations} 
            organizations={organizations} 
            setOrganization={setOrganization} 
            organization={selectedOrganization} 
            open={edit} 
            handleClose={handleEditClose} />

            <ViewOrganizationPopup 
            setOrganizations={setOrganizations} 
            organizations={organizations} 
            setOrganization={setOrganization} 
            organization={selectedOrganization} 
            open={view} 
            handleClose={handleViewClose} />

            <Box p={1} style={{ width: '400px' }}>

                <div name='organization headings'>
                    <div className={classes.root}>
                        <div className={classes.section1}>
                            <Grid container>
                                <Grid item xs className={classes.minH}>
                                    <Typography gutterBottom variant="h4">
                                        {organization.name}
                                    </Typography>
                                </Grid>

                            </Grid>
                            {/* <Typography color="textSecondary" variant="body2">
                                <LocationOn /> {organization.city}
                                {organization.verified_on === null &&
                                    <Chip style={{ float: 'right' }} className={classes.chip} color="secondary" label="" />
                                }
                            </Typography> */}
                        </div>
                        <Divider variant="middle" />
                        <div className={classes.section2}>
                            {/* <Typography gutterBottom variant="body1">
                                Plan
                </Typography> */}
                            <div>

                                <Chip title="plan" className={classes.chip} color="primary" 
                                label={organization.plan.name} 
                                />

                            </div>
                        </div>
                        <div className={classes.section3}>
                            <Button onClick={handleViewOpen} color="primary">View Organization</Button>
                        </div>


                        <IconButton aria-label="delete">
                            <Delete onClick={() => Swal.fire({
                                title: 'Are you sure?',
                                text: "You won't be able to undo this process!",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Delete!'
                            }).then((result) => {
                                Swal.fire({
                                    title: 'Enter Organization Name to Continue',
                                    text: 'All records associated with the organization will be deleted',
                                    input: 'text',
                                    inputValidator: (value) => {
                                        if (value === organization.name) {
                                            if(result.isConfirmed && deleteOrg(organization.id)){
                                                Swal.fire(
                                                    'Deleted!',
                                                    'Organization has been deleted.',
                                                    'success'
                                                )
                                            }else Notification.warning("Network Error")
                                        }else return 'Organization name is case sensitive check your spelling'
                                    }
                                })
                            })} />
                        </IconButton>
                        <IconButton onClick={handleEditOpen} aria-label="edit">
                            <Edit />
                        </IconButton>
                        
                        {/* <Chip title="plan" className={classes.chip} style={{ float: 'right', borderColor:'red', color:'red' }} color="" label= "Reject" onClick={handleViewOpen} />
                        <Chip title="plan" className={classes.chip} color="" label= "Approve" onClick={handleViewOpen} style={{ float: 'right', borderColor:'red', borderWidth:'50px', color:'green' }} /> */}
                        {/* <button onClick={handleViewOpen} style={{ float: 'right', backgroundColor:'white', borderColor:'red', borderWidth:'1.8px', borderRadius:'15px', color:'red', marginLeft:'10px', width:'100px', marginTop:'10px' }}> Reject</button>
                        <button onClick={handleViewOpen} style={{ float:'right', backgroundColor:'white', borderColor:'green', borderWidth:'1.8px', borderRadius:'15px', color:'green', width:'100px', marginTop:'10px' }}> Approve</button> */}
                    </div>
                </div>
            </Box>
        </>
    )
}
const mapDispatchToProps = () => {
    return { getOrganization, deleteOrganization }
}

export default connect(
    null,
    mapDispatchToProps(),
)(OrganizationCard);