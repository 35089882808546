export const SET_CURRENT_USER = "setCurrentUser";

export const GOOGLE_SIGN_IN_REQUEST = "googleSIgnInRequest";
export const GOOGLE_SIGN_IN_FAILURE = "googleSignInFailure";
export const GOOGLE_SIGN_IN_SUCCESS = "googleSignInSuccess";

export const USER_SIGN_UP_REQUEST = "userSignUpRequest";
export const USER_SIGN_UP_SUCCESS = "userSignUpSuccess";
export const USER_SIGN_UP_FAILURE = "userSignUpFailure";

export const USER_SIGN_IN_REQUEST = "userSignInRequest";
export const USER_SIGN_IN_SUCCESS = "userSignInSuccess";
export const USER_SIGN_IN_FAILURE = "userSignInFailure";

export const USER_SIGN_OUT_REQUEST = "userSignOutRequest";
export const USER_SIGN_OUT_FAILURE = "userSignOutFailure";
export const USER_SIGN_OUT_SUCCESS = "userSignOutSuccess";

export const GOOGLE_SIGN_UP_REQUEST = "googleSignUpRequest";
export const GOOGLE_SIGN_UP_SUCCESS = "googleSignUpSuccess";
export const GOOGLE_SIGN_UP_Failure = "googleSignUpFailure";

export const EDIT_USER_PROFILE_REQUEST = "editUserProfileRequest";
export const EDIT_USER_PROFILE_SUCCESS = "editUserProfileSuccess";
export const EDIT_USER_PROFILE_FAILURE = "editUserProfileFailure";

export const RECOVER_PASSWORD_REQUEST = "recoverPasswordRequest";
export const RECOVER_PASSWORD_SUCCESS = "recoverPasswordSuccess";
export const RECOVER_PASSWORD_FAILURE = "recoverPasswordFailure";

export const USER_LOG_OUT_REQUEST = "userLogoutRequest";

