import React, { useState, useEffect } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import {Link, useNavigate} from 'react-router-dom';
import moment from 'moment'

import { currentUser } from "../redux/user/user.selectors";
import { UserAllLevies } from "../redux/levy/levy.actions";
import { currencyFormat } from "../helpers/hookLib/helperFunction";
import { useFormFields } from "../helpers/hookLib/useFormFields";

import { Table } from "react-bootstrap";
import AmountModalBox from "../Components/UserComponents/AmountModalBox";
import {TextField, Card, CardContent,
  Typography, makeStyles, Button,DialogContent,
  createMuiTheme, withStyles, Dialog,DialogActions 
} from "@material-ui/core";
import NavigateNextTwoToneIcon from "@material-ui/icons/NavigateNextTwoTone";
import NavigateBeforeTwoToneIcon from "@material-ui/icons/NavigateBeforeTwoTone";

import { purple, green, blue } from '@material-ui/core/colors';


const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  pagination: {
    width: "19%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "17px",
    marginTop: "10px",
  },
  paginationBtm: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  inner: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  paginationBtm: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  nav: {
    cursor: "pointer",
  },
  btn: {
    display: "inlineBlock",
    fontWeight: "400",
    color: "#212529",
    textAlign: "center",
    verticalAlign: "middle",
    webkitUserSelect: "none",
    userSelect: "none",
    backgroundColor: "transparent",
    border: "1px solid transparent",
    fontSize: "1rem",
    lineHeight: "1.5",
    borderRadius: "0.25rem",
    textDecoration: "none!important",
    transition: "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out"
  }
}));

const style = {
  display: 'flex',
  flexWrap: 'nowrap',
}

const theme = createMuiTheme({
  palette: {
    primary: purple,
  },
});

const PurpleButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
    },
  },
}))(Button);

const GreenButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);

const BlueButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  },
}))(Button);

// const AmountModalBox = (props) => {
//   const [newAmount,setnewAmount] = useState('')
//   const total = Number(newAmount) + Number(props.data.amount_paid)
//   // console.log(total)
//   const proceed = () => {
//     if (
//       newAmount !== undefined &&
//       newAmount > 0 &&
//       props.data.levy.payment_type !== "voluntary" &&
//       props.data.levy.instalmental_plan === "yes"
//     ) {
//       if (total > Number(props.data.levy_group.amount)) {
//         if(Number(props.data.balance) < Number(props.data.levy_group.minimum_amount) && Number(newAmount) < Number(props.data.balance) || Number(newAmount) > Number(props.data.balance) ){
//           Notification.error(`Balance is ${props.data.balance}`);
//         }
//         else
//           Notification.error("Can not pay more than the set bill amount");
//       }else if (
//         Number(newAmount) <
//         Number(props.data.levy_group.minimum_amount) && Number(props.data.balance) > Number(props.data.levy_group.minimum_amount)
//       ) {
//         Notification.error(
//           `The minimum Amount is ${props.data.levy_group.minimum_amount}`
//         );
//       } else if ( Number(props.data.balance) < Number(props.data.levy_group.minimum_amount) && Number(newAmount) < Number(props.data.balance)
//       ) {
//         Notification.error(`Balance is ${props.data.balance}`);
//       } else {
//         // redirect to payment page
//         return (
//           props.navigate(
//            `/levy/payment/${props.data.id}?levy_id=${props.data.levy_id}&amount=${newAmount}`
//           )
//         )
//       }
//     }else {
//       return (
//         props.navigate(
//          `/levy/payment/${props.data.id}?levy_id=${props.data.levy_id}&amount=${newAmount}`
//         )
//       )
//     }
//   }
//   return(
//     <Dialog open={props.open} onClose={props.close} maxWidth="sm" fullWidth>
//       <DialogContent>
//         <TextField
//             margin="dense"
//             id="amount"
//             // label="Capable Amount"
//             placeholder={
//               props.data.levy.levy_type != "voluntary"
//                 ? "Minimum amount to pay is " + props.data.levy_group.minimum_amount
//                 : "Enter capable amount to pay"
//             }
//             onChange={(e) => {
//               setnewAmount(e.target.value);
//             }}
//             type="number"
//             fullWidth
//             required
//             // defaultValue={data.levy_group.minimum_amount != null ? data.levy_group.minimum_amount : data.levy_group.newAmount}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => proceed()}>Proceed to Pay</Button>
//         </DialogActions>
//     </Dialog>
//   )
// }

const LevysDatasTable = ({ sno, data }) => {
  const url_R = `/print-receipt/${data.id}?levy_id=${data.levy_id}`
  const navigate = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const PrintReciept = () => {
      return (
        <Link className={classes.btn} to={url_R}>
          <GreenButton>
            Print Reciept
          </GreenButton>
        </Link>
      )
  }

  const Checkout = () => {
    const proceed = () => {
      if (data.balance && data.levy.instalmental_plan === "no") {
        // redirect to payment page
        navigate(`/levy/payment/${data.id}?levy_id=${data.levy_id}`);
      } 
       else if (
        data.levy_group.newAmount !== undefined &&
        data.levy_group.newAmount > 0
      ) {
        // redirect to payment page
        navigate(
          `/levy/payment/${data.id}?levy_id=${data.levy_id}&amount=${data.levy_group.newAmount}`
        );
      }
    };
    return (
    <>
      {data.levy.instalmental_plan === "no" ? <PurpleButton onClick={() => proceed()}>Proceed to Pay</PurpleButton> : 
      <BlueButton onClick={() => handleOpen()}>Enter Amount</BlueButton>
      }
    </>);
  };

  const levy_type = data.levy.levy_type === "voluntary" ? true : false;

  return (
    <tr>
      <AmountModalBox data={data} open={open} close={handleClose} navigate={navigate}/>
      <td>{sno}</td>
      <td>{data.levy !== "" ? data.levy.name : ""}</td>
      {levy_type ? <td colSpan="1"></td> : <td>{currencyFormat(data.levy_group.amount)}</td>}
      <td>{currencyFormat(data.amount_paid)}</td>
      {levy_type ? <td colSpan="3"></td> : <td>{currencyFormat(data.balance)}</td>}
      {levy_type ? "" :  <td>{moment(data.levy_group.deadline).format("DD-MM-YYYY")}</td>}
      {levy_type ? "" : <td>{data.status}</td>}
      <td style={{ width: '170px' }}>
          <div style={style}>
            {data.status === "paid" ? PrintReciept() : Checkout()}
          </div>
      </td>
    </tr>
  );
};

const AllLevies = ({ UserAllLevies, user }) => {
  const user_id = user.id;
  const [fields, handleFields] = useFormFields({ amount: "" });

  const [levies, setLevies] = useState([]);
  const [q, setQ] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [nextPage, setNextPage] = React.useState(1);
  const [prevPage, setPrevPage] = React.useState(0);
  const [pageSize] = React.useState(15);
  const [pageLimit] = React.useState(3);
  const [totalItems, setTotalItems] = React.useState(null);
  const [totalPages, setTotalPages] = React.useState(1);
  const [color, setColor] = React.useState("grey");

  const fetchData = (pageNumber = currentPage) => {
    UserAllLevies(user_id, pageNumber, pageSize, callback);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const callback = ({ data, currentPage, totalPages, totalItems }) => {
    setLevies(data);
    setCurrentPage(currentPage);
    setNextPage(currentPage + 1);
    setPrevPage(currentPage - 1);
    setTotalItems(totalItems);
    setTotalPages(totalPages);
  };

  const paginate = (page) => {
    fetchData(page);
  };

  const pageNumb = () => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    if(start > totalPages){
      return new Array().fill((_, idx) => start + idx + 1);
    }else
      return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
  };

  const paginatePrev = () => {
    if (currentPage > 1) {
      fetchData(prevPage);
      const prev = color === "black" ? "black" : "grey";
    } else {
      const prev = color === "grey" ? "grey" : "black";
    }
  };

  const paginateNext = () => {
    if (currentPage < totalPages) {
      fetchData(nextPage);
      const prev = color === "black" ? "black" : "grey";
    } else {
      const prev = color === "grey" ? "grey" : "black";
    }
  };

  const classes = useStyles();
  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h5">My Bills</Typography>
          <div className={classes.pagination}>
            <div
              onClick={() => paginatePrev()}
              id={currentPage === 1 ? "prev" : ""}
              className={classes.nav}
            >
              <NavigateBeforeTwoToneIcon />
            </div>
            <div>{pageNumb().map((page, index) => (
                <button
                  key={index}
                  onClick={() => paginate(page)}
                  className={page === currentPage ? "btnactive" : "btnpagination"}
                  disabled={page > totalPages}
                  style={{display: page > totalPages ? 'none' : ''}}
                >
                  <span>{page}</span>
                </button>
                ))}
            </div>
            <div
              onClick={() => paginateNext()}
              id={currentPage === totalPages ? "next" : ""}
              className={classes.nav}
            >
              <NavigateNextTwoToneIcon />
            </div>
          </div>
          <Table responsive="lg" striped bordered>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Decription</th>
                <th>Bills Due</th>
                <th>Amount Paid</th>
                <th>Balance</th>
                <th>Deadline</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {levies.length ? (
                levies.map((bill, index) => (
                  <LevysDatasTable
                    sno={pageSize * (currentPage - 1) + index + 1}
                    key={bill.id}
                    fields={fields}
                    handleFields={handleFields}
                    data={bill}
                  />
                ))
              ) : (
                <tr>
                  {" "}
                  <td colSpan="9" style={{ textAlign: "center" }}>
                    No levies found
                  </td>{" "}
                </tr>
              )}
            </tbody>
          </Table>
          <div className={classes.paginationBtm}>
            <div className={classes.inner}>
              <div
                onClick={() => paginatePrev()}
                id={currentPage === 1 ? "prev" : ""}
                className={classes.nav}
              >
                <NavigateBeforeTwoToneIcon />
              </div>
              <div>{pageNumb().map((page, index) => (
                <button
                  key={index}
                  onClick={() => paginate(page)}
                  className={page === currentPage ? "btnactive" : "btnpagination"}
                  disabled={page > totalPages}
                  style={{display: page > totalPages ? 'none' : ''}}
                >
                  <span>{page}</span>
                </button>
                ))}
              </div>
              <div
                onClick={() => paginateNext()}
                id={currentPage === totalPages ? "next" : ""}
                className={classes.nav}
              >
                <NavigateNextTwoToneIcon />
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  user: currentUser,
});

export default connect(mapStateToProps, { UserAllLevies })(AllLevies);
