// import { html2canvas } from 'html2canvas'

export const currencyFormat = (value) =>
  new Intl.NumberFormat('NG-FA', {
    style: 'currency',
    currency: 'NGN',
}).format(value);

// export const  printDocument = () => {
//   const doc = document.getElementById('levyToPrint');
//   html2canvas(doc)
//     .then((canvas) => {
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF();
//       pdf.addImage(imgData, 'JPEG', 0, 0);
//       pdf.save("Receipt.pdf");
//     })
//   ;
// }

export const getCurrentDate = (separator = '/') => {

  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
}