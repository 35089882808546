import React, {useState} from "react";
import { connect } from "react-redux";
import {TextField,
    Typography, makeStyles, Button,DialogContent,
    createMuiTheme, withStyles, Dialog,DialogActions 
  } from "@material-ui/core";
import Notification from "../../helpers/notification";

const AmountModalBox = (props) => {
    const [newAmount,setnewAmount] = useState('')
    const total = Number(newAmount) + Number(props.data.amount_paid)
    const proceed = () => {
      if (
        newAmount !== undefined &&
        newAmount > 0 &&
        props.data.levy.payment_type !== "voluntary" &&
        props.data.levy.instalmental_plan === "yes"
      ) {
        if (total > Number(props.data.levy_group.amount)) {
          if(Number(props.data.balance) < Number(props.data.levy_group.minimum_amount) && Number(newAmount) < Number(props.data.balance) || Number(newAmount) > Number(props.data.balance) ){
            Notification.error(`Balance is ${props.data.balance}`);
          }
          else
            Notification.error("Can not pay more than the set bill amount");
        }else if (
          Number(newAmount) <
          Number(props.data.levy_group.minimum_amount) && Number(props.data.balance) > Number(props.data.levy_group.minimum_amount)
        ) {
          Notification.error(
            `The minimum Amount is ${props.data.levy_group.minimum_amount}`
          );
        } else if ( Number(props.data.balance) < Number(props.data.levy_group.minimum_amount) && Number(newAmount) < Number(props.data.balance)
        ) {
          Notification.error(`Balance is ${props.data.balance}`);
        } else {
          // redirect to payment page
          return (
            props.navigate(
             `/levy/payment/${props.data.id}?levy_id=${props.data.levy_id}&amount=${newAmount}`
            )
          )
        }
      }else {
        return (
          props.navigate(
           `/levy/payment/${props.data.id}?levy_id=${props.data.levy_id}&amount=${newAmount}`
          )
        )
      }
    }
    return(
      <Dialog className={props.className} open={props.open} onClose={props.close} maxWidth="sm" fullWidth>
        <DialogContent>
          <TextField
              margin="dense"
              id="amount"
              // label="Capable Amount"
              placeholder={
                props.data.levy.levy_type != "voluntary"
                  ? "Minimum amount to pay is " + props.data.levy_group.minimum_amount
                  : "Enter capable amount to pay"
              }
              onChange={(e) => {
                setnewAmount(e.target.value);
              }}
              type="number"
              fullWidth
              required
              // defaultValue={data.levy_group.minimum_amount != null ? data.levy_group.minimum_amount : data.levy_group.newAmount}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => proceed()}>Proceed to Pay</Button>
          </DialogActions>
      </Dialog>
    )
}

export default connect(
    null,
    null,
)(AmountModalBox);