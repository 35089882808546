import React, { useState, useEffect } from 'react';

import { listOrganization } from '../redux/organization/organization.actions';
import { connect } from 'react-redux';


import Box from '@material-ui/core/Box';

import { AddCircleOutline } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import OrganizationCard from '../Components/OrganizationComponents/OrganizationCard.component';
import AddOrganizationPopup from '../Components/OrganizationComponents/AddOrganizationPopup.component';

////Organization Page
const OrganizationPage = ({ listOrganization }) => {

    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [view, setView] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const [organization, setOrganization] = useState();

    useEffect(async () => {
        await listOrganization(callback);
    }, []);

    const callback = (response) => {
        setOrganizations(response.data);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    const OrganizationList = () => organizations.map((value, index) => <OrganizationCard organization={value} edit={edit} setEdit={setEdit} view={view} setView={setView} setOrganizations={setOrganizations} organizations={organizations} setOrganization={setOrganization} selectedOrganization={organization} key={index} />) //Map to Organization Card Component

    return (

        <>
            {/* <AddOrganizationPopup organizations={organizations} setOrganizations={setOrganizations} open={open} handleClose={handleClose} />

            <div style={{ margin: '10px' }}>

                <Button
                    onClick={handleClickOpen}
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<AddCircleOutline />}>
                    Add Organization
                </Button>

            </div> */}

            {/* Organization Cards Starts */}

            <Box display='flex' flexDirection='row' flexWrap='wrap'>

                {OrganizationList()}

            </Box>

            {/* Organization Cards ends */}


        </>
    );

}

const mapDispatchToProps = () => {
    return { listOrganization }
}

export default connect(
    null,
    mapDispatchToProps(),
)(OrganizationPage);


