import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';

import { organization } from '../redux/organization/organization.selectors';
import {GetSubscriptions } from '../redux/organization/organization.actions';

import {Card,CardContent,CardHeader} from '@material-ui/core';
import { Table } from 'react-bootstrap';
import { listPlan } from '../redux/plan/plan.actions';

const SubscriptionTable = ({subscriptions, planSub, sn}) => {
   
    return(
        <tr>
            <td>{sn}</td>
            <td>{planSub ? planSub.name : null}</td>
            <td>{planSub ? planSub.amount : null}</td>
            <td>{moment(subscriptions.createdAt).format("Do MMM YYYY")}</td>
            <td>{moment(`${subscriptions.expiresAt}`, "YYYY-MM-DD HH:MM:SS").format("Do MMM YYYY")}</td>
        </tr>
    )
}

const SubscriptionsTable = ({GetSubscriptions, organization, listPlan}) => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [plan, setPlan] = useState([])


    const getSubscriptionPlan = () => {
        // var plans = []
        var mPlan;
        subscriptions.forEach(v => {
            plan.forEach(p => {
                if(p.id === v.plan_id){
                    mPlan = Object.assign({}, p)
                    // plans.push(mPlan)
                }
            });
        });
        return mPlan
    }

    const dPlan = getSubscriptionPlan()

    useEffect(() => {
        GetSubscriptions(callback)
        listPlan( mCallback)
    }, [])

    const callback = (response) => {
        if(response.status){
            setSubscriptions(response.data)
        }else console.log(response.message)
    }

    const mCallback = (response) => {
        if(response.status){
            setPlan(response.data)
        }else console.log(response.message)
    }

    return(
        <Card>
            <CardHeader titleTypographyProps={{ variant: 'h4' }} title='Subscription'/>
            <CardContent>
                <Table responsive='lg' striped bordered>
                    <thead>
                        <tr>
                            <th>S/N</th>
                            <th>Plan Type</th>
                            <th>Amount</th>
                            <th>Date Subscribed</th>
                            <th>Expiry Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subscriptions.length ? subscriptions.map((v,idx) => <SubscriptionTable 
                        sn={idx+1}
                        key={v.id}
                        subscriptions={v}
                        planSub={dPlan}
                        />) : <tr> <td colSpan="7" style={{ textAlign: 'center' }}></td> </tr>}
                    </tbody>
                </Table>
            </CardContent>
        </Card>
    )
}

export default connect(() => createStructuredSelector(
    {organization: organization}
), {GetSubscriptions, listPlan})(SubscriptionsTable);