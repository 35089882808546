import { lazy } from "react";

import { Navigate } from 'react-router-dom';
import { HomeTwoTone, LockOpenTwoTone, ErrorTwoTone, Business, AccountCircleTwoTone, BallotTwoTone } from '@material-ui/icons';
import { PrivateRoute, UnAuthRoute, AdminRoute, ITLRoute } from './RouteMiddleware';
import CreateInformation from "../views/CreateInformation";
import Outstanding from "../Components/admincomponents/BillsDue";
import AllLevies from "../views/AllLevies";
import Subscriptions from "../views/Subscriptions";
//import {History} from '../History'
//import { createBrowserHistory } from "history";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));
const Header = lazy(() => import("../layouts/FullLayout/Header/Header"));
const BlankLayout = lazy(() => import("../layouts/BlankLayout/BlankLayout.js"));
/****End Layouts*****/

/*****Pages******/
const Home = lazy(() => import("../views/Home"));
const OutStanding = lazy(() => import("../views/OutStanding"));
const LandingPage = lazy(() => import("../views/HomeScreen"));
const Verification = lazy(() => import("../views/VerifyEmail"));

const Error = lazy(() => import("../views/error/error"));
const HostNotFound = lazy(() => import("../views/error/website_not_found"));
const Login = lazy(() => import("../views/Login"));
const AdminLogin = lazy(() => import("../views/AdminLogin"));
const Register = lazy(() => import("../views/Register"));
const DataTable = lazy(() => import("../views/Table"));
const OrganizationPage = lazy(() => import("../views/Organization"));
const ListOrganizationPage = lazy(() => import("../Components/UserComponents/ListOrganizationUsers.component"));
const VerifyNewUser = lazy(() => import("../Components/UserComponents/VerifyUser.component"));
const IndividualPayment = lazy(() => import("../views/IndividualPayment"));
const LoanList = lazy(() => import("../views/Microfinance/Admin/Loans"));
const LoanRepayment = lazy(() => import("../views/Microfinance/Admin/CreateLoanRepayment"));
const WithdrawalList = lazy(() => import("../Components/UserComponents/AdminWithdrawal"))


// levy routes
const ListLevyPage = lazy(() => import("../views/Levy"));

const ProfilePage = lazy(() => import("../views/Profile"));
const ChartPage = lazy(() => import("../views/Chart"));
const ForgotPassword = lazy(() => import("../views/reset_password/ForgotPassword"));
const Payment = lazy(() => import("../views/Payment"));
const ThisUser = lazy(() => import("../views/ThisUserPayment"));
const EmailRequest = lazy(() => import("../views/reset_password/EmailRequest.js"));
const ChangePassword = lazy(() => import("../views/ChangePassword"))
const ListPlanPage = lazy(() => import("../views/Plan"));
const ManageUser = lazy(() => import("../views/ManageUser"));
const ViewMyPayments = lazy(() => import("../views/ViewMyPayments"))
const RgOganization = lazy(() => import("../views/RgOrganization"))
const OrgBillingDetails = lazy(() => import("../views/OrgBillingDetails"));
const PaymentGroups = lazy(() => import("../views/PaymentGroup"));
const PaymentGroupLists = lazy(() => import("../views/PaymentGroupList"));
const ViewLevy = lazy(() => import("../../src/views/ViewLevy.private"));
// const UserViewsLevies = lazy(() => import("../views/UserViewsLevies"));
const Receipt = lazy(() => import("../views/Receipt"))
const LevyBillDetails = lazy(() => import("../views/LevyBillDetails"));
const ProfileImage = lazy(() => import("../views/ProfileImage"));
// const BillsPaid = lazy(() => import("../views/BillsPaid"));
const OrgProfile = lazy(() => import("../views/OrganizationProfile"));
const LoanProcessing = lazy(() => import("../views/Microfinance/Admin/LoanCataloguePage"))
const LoansApplied = lazy(() => import("../views/Microfinance/Customer/LoanHistory"))
const InputLoanAmount = lazy(() => import("../views/Microfinance/Customer/LoanPayPoint"))
const PayPoint = lazy(() => import("../views/Microfinance/Customer/PaystackPayPoint"))
const CustomerDeposit = lazy(() => import("../views/Microfinance/Customer/MakeSaving"))
const AddAccountType = lazy(() => import("../views/Microfinance/Admin/CreateAccountType"))
const LoanDetail = lazy(() => import("../views/Microfinance/Admin/ViewLoanDetail"))
// const Paid = lazy(()=> import("../Components/admincomponents/Paid"))
// const Unpaid = lazy(()=> import("../Components/admincomponents/Unpaid"))
const AccountType = lazy(() => import("../views/AccountType"));
const LoanApplicationForm = lazy(() => import("../views/LoanApplicationForm"));
const SavingAccount = lazy(() => import("../views/Microfinance/Customer/CreateSavingAccount"))
const ActiveLoan = lazy(() => import("../views/Microfinance/Customer/ActiveLoan"))


/*****Routes******/
const ThemeRoutes = [
	{
		path: "/",
		element: <LandingPage />
	},
	{
		path: "/",
		element: <FullLayout />,

		children: [
			{ path: "dashboard", icon: HomeTwoTone, exact: true, element: <PrivateRoute component={Home} /> },
			{ path: "organizations", icon: Business, exact: true, element: <ITLRoute component={OrganizationPage} />},
			{ path: "table", icon: BallotTwoTone, element: <DataTable /> },
			{ path: "profile", icon: AccountCircleTwoTone, element: <PrivateRoute component={ProfilePage} /> },
			{ path: "organizations/:id", exact: true, element: <PrivateRoute component={ListOrganizationPage} /> },
			{ path: "levies", exact: true, element: <AdminRoute component={ListLevyPage} /> },
			{ path: "plans", exact: true, element: <PrivateRoute component={ListPlanPage} /> },
			{ path: "manageuser", exact: true, element: <AdminRoute component={ManageUser} /> },
			{ path: "userdetails", exact: true, element: <AdminRoute component={ListOrganizationPage} /> },
			{ path: "verifyuser", exact: true, element: <AdminRoute component={VerifyNewUser} /> },
			{ path: "withdrawalrequest", exact: true, element: <AdminRoute component={WithdrawalList} /> },
			{ path: "my-payments/:id", exact: true, element: <PrivateRoute component={ViewLevy} /> },
			{ path: "chart", icon: ErrorTwoTone, element: <ChartPage /> },
			{ path: "all-levies", exact: true, element: <PrivateRoute component={AllLevies} /> },
			{ path: "payments", exact: true, element: <AdminRoute component={Payment} /> },
			{ path: "organization-profile", exact: true, element: <AdminRoute component={OrgProfile} /> },
			{ path: "/payments/:id", exact: true, element: <AdminRoute component={ThisUser} /> },
			{ path: "change-password", exact: true, element: <PrivateRoute component={ChangePassword} /> },
			{ path: "my-payments", exact: true, element: <PrivateRoute component={ViewMyPayments} /> },
			{ path: "paymentGroups/:id/:levyid", exact: true, element: <AdminRoute component={PaymentGroups} /> },
			{ path: "Paymentgrouplists/:id", exact: true, element: <AdminRoute component={PaymentGroupLists} /> },
			{ path: "individual", exact: true, element: <AdminRoute component={IndividualPayment} /> },
			// { path: "view/levies", exact: true, element: <PrivateRoute component={UserViewsLevies} /> },
			{ path: "print-receipt/:id", exact: true, element: <PrivateRoute component={Receipt} /> },
			{ path: "/levy/payment/:id", exact: true, element: <PrivateRoute component={LevyBillDetails} /> },
			{ path: "profileimg", exact: true, element: <PrivateRoute component={ProfileImage} /> },
			// { path: "paid-bills", exact: true, element: <PrivateRoute component={BillsPaid} /> },
			{path: "post-information", exact: true, element: <AdminRoute component={CreateInformation}/>},
			{path: "userdetails/bills-due/", exact: true, element: <AdminRoute component={Outstanding}/>},
			{path: "subscriptions", exact: true, element: <AdminRoute component={Subscriptions}/>},
			{path: "loan-processing", exact: true, element: <AdminRoute component={LoanProcessing}/>},
			{path: "loans", exact: true, element: <PrivateRoute component={LoansApplied}/>},
			{path: "repay-loan/", exact: true, element: <PrivateRoute component={InputLoanAmount}/>},
			{path: "account/", exact: true, element: <PrivateRoute component={InputLoanAmount}/>},
			{path: "pay-point/:id", exact: true, element: <PrivateRoute component={PayPoint}/>},
			{path: "savings", exact: true, element: <PrivateRoute component={CustomerDeposit}/>},
			{path: "accounts", exact: true, element: <AdminRoute component={AddAccountType}/>},
			{path: "loan-applications", exact: true, element: <AdminRoute component={LoanList}/>},
			{path: "loan-details/:loan_id", exact: true, element: <AdminRoute component={LoanDetail}/>},
			{ path: "accounttypes", exact: true, element: <AdminRoute component={AccountType} /> },
			{ path: "loanapplicationform", icon: AccountCircleTwoTone, element: <PrivateRoute component={LoanApplicationForm} /> },
			{ path: "loan-repayment", icon: AccountCircleTwoTone, element: <AdminRoute component={LoanRepayment} /> },
			{path: "create-account", exact: true, element:<PrivateRoute component={SavingAccount} />},
			{path: "active-loan", exact: true, element:<PrivateRoute component={ActiveLoan}/>},
			// {path: "userdetails/paid/", exact: true, element: <AdminRoute component={Paid}/>},
			// {path: "userdetails/unpaid/", exact: true, element: <AdminRoute component={Unpaid}/>},
			{ path: '*', element: <Navigate to="/404" /> }
		]
	},
	{
		path: "/",
		element: <BlankLayout />,
		children: [
			{ path: "verifyEmail", icon: HomeTwoTone, exact: true, element: <PrivateRoute component={Verification} /> },
			{ path: "404", icon: ErrorTwoTone, element: <Error /> },
			{ path: "host-not-found", icon: ErrorTwoTone, element: <HostNotFound /> },
			{ path: "forgot-password", exact: true, element: <UnAuthRoute component={EmailRequest} /> },
			{ path: "email/change-password", exact: true, element: <UnAuthRoute component={ForgotPassword} /> },
			{ path: "login", icon: LockOpenTwoTone, element: <UnAuthRoute component={Login} /> },
			{ path: "admin/login", icon: LockOpenTwoTone, element: <UnAuthRoute component={AdminLogin} /> },
			{ path: "register", icon: LockOpenTwoTone, element: <UnAuthRoute component={Register} /> },
			{ path: "register/organization", exact: true, element: <RgOganization /> },
			{ path: "confirm-payment", exact: true, element: <OrgBillingDetails /> },
		]
	},
];

export default ThemeRoutes;
