import api from '../../helpers/api';
import Notification from '../../helpers/notification'


export const addPlan = (planData, callback) => async (dispatch) => {

    console.log(planData)

    await api.post('plans', planData).then((response) => {
        if (response.data.status) {
            Notification.success('Plan Added Successfully!')
            callback(response.data);

        } else {
            Notification.error(response.data.message)
            console.log('error')
            // callback(response.data);

        }
    }).catch(error => Notification.error(error));
}


export const updatePlan = (planData, callback) => async (dispatch) => {

    await api.put(`plans/${planData.id}`, planData).then((response) => {
        if (response.data.status) {
            Notification.success('Plan Updated Successfully!')
            callback(response.data);

        } else {
            Notification.error(response.data.message)
            console.log('error')
            // callback(response.data);

        }
    }).catch(error => Notification.error(error));
}

export const listPlan = (callback) => async (dispatch) => {

    await api.get('plans').then((response) => {
        if (response.data.status) {
            callback(response.data);

        } else {
            Notification.error(response.data.message)
            console.log('error')
            // callback(response.data);

        }
    }).catch(error => Notification.error(error));
}

export const deletePlan = (id, callback) => async (dispatch) => {

    await api.delete(`plans/${id}`).then((response) => {
        if (response.data.status) {
            callback(id, response.data);

        } else {
            Notification.error(response.data.message)
            console.log('error')
            // callback(response.data);

        }
    }).catch(error => Notification.error(error));
}

export const getPlan = (id, callback) => async (dispatch) => {

    await api.get(`plans/${id}`).then((response) => {
        if (response.data.status) {
            callback(response.data);
        } else {
            Notification.error(response.data.message)
            console.log('error')
            // callback(response.data);

        }
    }).catch(error => Notification.error(error));
}