/* eslint-disable linebreak-style */
/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

export const selectOrganization = (state) => state.organization;
// export const selectURL = () => /:\/\/([^\/]+)/.exec(window.location.href)[1];
export const selectURL = () => /:\/\/([^\/]+)/.exec('http://ashamfb.cipma.ng')[1];
// const selectURL = () => /:\/\/([^\/]+)/.exec("http://cipma.ng")[1];

// export const selectIsOrganizationLoading = createSelector(
//   [selectOrganization],
//   (organization) => organization.isFetching
// );

export const myOrganization = createSelector(
  [selectOrganization], (organization) => {
    return organization.id ? {} : organization;
  }
);

export const organization = createSelector(
  [selectOrganization], (organization) => {
    return organization;
  }
);

export const isCipma = createSelector(
  [selectURL], (host) => host.split('.')[0] === "cipma" || host.split('.')[0] === "www" ? true : false
);

export const isMFB = createSelector(
  [selectOrganization], (organization) => organization && organization.type === "Microfinance" ? true : false
);

// export const selectCurrentUserToken = createSelector(
//   [selectUser],
//   (user) => user.token
// );


// export const selectOrganizationError = createSelector(
//   [selectOrganization],
//   (organization) => organization.errorMessage
// );

// export const selectRecovered = createSelector(
//   [selectOrganization],
//   (organization) => organization.recovered
// );
