import api from '../../helpers/api';
import axios from 'axios';
import Notification from '../../helpers/notification'
import { SET_ORGANIZATION, UNSET_ORGANIZATION, LOGO_SUCCESS } from './organization.actionTypes';


export const saveOrganization = (organizationData) => ({
    type: SET_ORGANIZATION,
    payload: organizationData
});


export const removeOrganization = () => ({
    type: UNSET_ORGANIZATION,
});

export const updateLogoSuccess = (organization) => ({
    type: LOGO_SUCCESS,
    payload: organization
});

// user update profile
export const updateLogo = (data) => async (dispatch) => {
    dispatch(updateLogoSuccess(data));
};

export const addOrganization = (organizationData, callback) => async (dispatch) => {
    await api.post('/registerOrganization', organizationData).then((response) => {
        if (response.data.status) {
            dispatch(saveOrganization(organizationData))
            callback();
        } else {
            Notification.error(response.data.message)
        }
    }).catch(error => Notification.error(error));
}


export const updateOrganization = (id, organizationData, callback) => async (dispatch) => {

    await api.put(`organization/${id}`, organizationData).then((response) => {
        if (response.data.status) {
            dispatch(saveOrganization(response.data.data))
            Notification.success('Organization Updated Successfully!')
            callback(response.data);
        } else {
            Notification.error(response.data.message)
            console.log('error')
            // callback(response.data);

        }
    }).catch(error => Notification.error(error));
}

export const listOrganization = (callback) => async (dispatch) => {

    await api.get('organization').then((response) => {
        if (response.data.status) {
            callback(response.data);

        } else {
            Notification.error(response.data.message)
            console.log('error')
            // callback(response.data);

        }
    }).catch(error => Notification.error(error));
}

export const deleteOrganization = (id, callback) => async (dispatch) => {

    await api.delete(`organization/${id}`).then((response) => {
        if (response.data.status) {
            callback(id, response.data);

        } else {
            Notification.error(response.data.message)
            console.log('error')
            // callback(response.data);

        }
    }).catch(error => Notification.error(error));
}

//  for itl Admin
export const getOrganizationCount = (callback) => async () => {
    await api.get(`reports/organizations_count`).then(response =>{
        if(response.status){
            callback(response.data)
        }
    }).catch(err => console.log(err))
}

// for itl Admin
export const getPlansCount = (callback) => async () => {
    await api.get(`reports/plans_count`).then(response =>{
        if(response.status){
            callback(response.data)
        }
    }).catch(err => console.log(err))
}

// display user count data on admin dashboard
export const getUserCount = (callback) => async () => {
    await api.get(`reports/users_count`).then(response =>{
        if(response.status){
            callback(response.data)
        }
    }).catch(err => console.log(err))
}

export const getOrganization = (id, callback) => async (dispatch) => {

    await api.get(`organization/${id}`).then((response) => {
        if (response.data.status) {
            callback(id, response.data);

        } else {
            Notification.error(response.data.message)
        }
    }).catch(error => Notification.error(error));
}

export const registerOrganization = (corporate, callback) => async (dispatch) => {
    await api.post(`/registerOrganization`, corporate).then((response) => {
        if (response.data.status) {
            callback(response.data);
            Notification.success(response.data.message);
        } else {
            Notification.error(response.data.message)
        }
    }).catch(error => Notification.error(error))
}

export const getOrgDetails = (subdomain, url, callback) => async (dispatch) => {
    await axios.get(`${url}/?subdomain=${subdomain}`).then(async (response) => {
        if (response.data.status) {
            dispatch(saveOrganization(response.data.data))
            await callback(response.data.data)
        } else {
            Notification.error(response.message)
        }
    }).catch(error => Notification.error(error))
}

// Upload logo
export const uploadLogo = (imgData, callback, config) => async (dispatch) => {
    await api.put(`organization/upload-logo`, imgData, config).then(response => {
        if (response.data.status) {
            callback(response.data)
            Notification.success('Successfully Sent')
        } else {
            Notification.error('Network Error, try again.')
        }
    }).catch(err => console.log(err))
}


export const paystackKey = (data, callback) => async (dispatch) => {

    await api.post(`organization/paystack`, data).then((response) => {
        if (response.data.status) {
            Notification.success('Paystack Details Updated Successfully!')
            callback(response.data);
        } else {
            Notification.error(response.data.message)
            console.log('error')
            // callback(response.data);

        }
    }).catch(error => Notification.error(error));
}

export const getPaystackKey = (callback) => async (dispatch) => {

    await api.get(`organization/paystack`).then((response) => {
        if (response.data.status) {
            callback(response.data);

        } else {
            Notification.error(response.data.message)
        }
    }).catch(error => Notification.error(error));
}

export const SubscriptionRenewal = (data,callback) => async (dispatch) => {
    await api.post(`subscription`, data).then((response) => {
        if(response.status){
            callback(response.data);
        } else {
            // console.log(Notification.error(response.data.message))
            Notification.error(response.data.message)
        }
    }).catch(err => console.log(err))
}

export const GetSubscriptions = (callback) => async (dispatch) => {
    await api.get(`/subscription/org-subs`).then((response) => {
        if(response.status){
            callback(response.data);
        }else{
            console.log(Notification.error(response.data.message))
            console.log("am gone")
        }
    }).catch((err) => console.log(err))
}