import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import user from './user/user.reducers';
import organization from './organization/organization.reducers';
// import ui from './ui/ui.reducer';
// import profile from './profile/profile.reducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user','organization'],
  };
  
  const rootReducer = combineReducers({
    user,
    organization,
    // ui,
    // profile
  });
  
  export default persistReducer(persistConfig, rootReducer);
  


// const Rootreducers = () => {

// }

// export default Rootreducers;
