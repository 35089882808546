import React, { useEffect, useState } from 'react';
import { Route, Navigate } from 'react-router-dom'
import { useSelector } from "react-redux";

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const token = useSelector(state => state.user.token);
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    useEffect(() => {
        if (token) {
            setIsAuthenticated(true)
        } else {
            setIsAuthenticated(false)
        }
    }, [token])

    if (isAuthenticated === null) {
        return <></>
    }

    // return (
    //     <Route {...rest} render={props => {
    if (!isAuthenticated) {
        return (
            <Navigate to='/login' />
        )
    }
    else {
        return (
            <Component {...rest} />
        )
    }
    // }
    // }
    // />
    // );
};

export const UnAuthRoute = ({ component: Component, ...rest }) => {
    const token = useSelector(state => state.user.token);
    const [isAuthenticated, setIsAuthenticated] = useState(null)
    useEffect(() => {
        if (token) {
            setIsAuthenticated(true)
        } else {
            setIsAuthenticated(false)
        }
    }, [token])
    if (isAuthenticated === null) {
        return <></>
    }

    // return (
    //     <Route {...rest} render={props =>
    return isAuthenticated ? (
        <Navigate to='/dashboard' />
    ) : (
        <Component {...rest} />
    )
    //     }
    //     />
    // );
};

export const AdminRoute = ({ component: Component, ...rest }) => {
    const user = useSelector(state => state.user);
    const [isAuthenticated, setIsAuthenticated] = useState(null)
    const [isAdmin, setIsAdmin] = useState(null)
    useEffect(() => {
        if (user.token) {
            setIsAuthenticated(true)
            const role = user.currentUser.role;
            if (role === "org_admin") {
                setIsAdmin(true)
            } else {
                setIsAdmin(false)
            }
        } else {
            setIsAuthenticated(false)
        }
    }, [user.token])

    if (isAuthenticated === null) {
        return <></>
    }

    if (isAdmin === null) {
        return <Navigate to='/' />
    }

    // return (
    //     <Route {...rest} render={props =>
    return !(isAuthenticated && isAdmin) ? (
        <Navigate to='/login' />
    ) : (
        <Component {...rest} />
    )
    //     }
    //     />
    // );
};

export const ITLRoute = ({ component: Component, ...rest }) => {
    const user = useSelector(state => state.user);
    const [isAuthenticated, setIsAuthenticated] = useState(null)
    const [isAdmin, setIsAdmin] = useState(null)
    useEffect(() => {
        if (user.token) {
            setIsAuthenticated(true)
            const role = user.currentUser.role;
            if (role === "itl_admin") {
                setIsAdmin(true)
            } else {
                setIsAdmin(false)
            }
        } else {
            setIsAuthenticated(false)
        }
    }, [user.token])

    if (isAuthenticated === null) {
        return <></>
    }

    if (isAdmin === null) {
        return <Navigate to='/' />
    }

    // return (
    //     <Route {...rest} render={props =>
    return !(isAuthenticated && isAdmin) ? (
        <Navigate to='/login' />
    ) : (
        <Component {...rest} />
    )
    //     }
    //     />
    // );
};