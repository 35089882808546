// import { createStore } from "redux";
// import rootreducers from './Rootreducers'
// const store = createStore(rootreducers);
// export default store;

// import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';

//  import reducer from './user/user.reducers';

// const rootReducer = combineReducers({
//    user: reducer
// });

// const configureStore = () => {
//   return createStore(
//     rootReducer,
//     compose(applyMiddleware(thunk))
//   );
// };

// export default configureStore;

import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from './Rootreducers';

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

export const store = createStore(rootReducer, applyMiddleware(...middlewares));

export const persistor = persistStore(store);

export default { store, persistor };
