import * as actions from './user.actionTypes'

const reducer = (state = {}, action) => {



    switch (action.type) {

        case actions.USER_SIGN_UP_REQUEST:
            break;

        case actions.USER_SIGN_UP_SUCCESS:
            return {
                ...state, currentUser: action.payload
            }

        case actions.USER_SIGN_IN_FAILURE:
            return {
                ...state
            }

        case actions.SET_CURRENT_USER:
            break;

        case actions.GOOGLE_SIGN_IN_REQUEST:
            // return{ 
            //     ...state, currentUser }
            break;

        case actions.GOOGLE_SIGN_UP_REQUEST:
            return {
                ...state,
            }

        case actions.USER_SIGN_IN_REQUEST:

        // case actions.USER_SIGN_IN_FAILURE: 

        case actions.USER_SIGN_IN_SUCCESS:
            return {
                ...state, currentUser: action.payload.user, token: action.payload.token
            }

        case actions.USER_LOG_OUT_REQUEST:
            return {}

        case actions.EDIT_USER_PROFILE_SUCCESS:
            return {
                ...state, currentUser: action.payload
            }

        case actions.RECOVER_PASSWORD_SUCCESS:
            return {
                ...state
            }


        default:
            return state;
    }
}
export default reducer;