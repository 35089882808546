import * as actions from './organization.actionTypes'

const reducer = (state = {}, action) => {



    switch (action.type) {

        case actions.SET_ORGANIZATION:
            return action.payload

        case actions.LOGO_SUCCESS:
            return {
                ...state, logo_url: action.payload.logo_url
            }

        case actions.UNSET_ORGANIZATION:
            return null

        default:
            return state;
    }
}
export default reducer;